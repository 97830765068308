import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PrivateRoute } from '_embedded_packages/web-app-auth/components/PrivateRoute';
import { ROLES } from '@bondvet/roles';
import { Loading } from '@bondvet/web-app-lib/Loading';
import createHomeRoutes from 'home/pages';
import createAccountRoutes from 'account/pages';
import createAuthRoutes from '_embedded_packages/web-app-auth/pages';
import createClinicControlCenterRoutes from 'clinicControlCenter/pages';
import createCompanyWideSettingsRoutes from 'companyWideSettings/pages';
import createDashboardRoutes from 'clinicData/pages';
import createGoogleRoutes from 'google/pages';
import createOauthRoutes from 'oauth/pages';
import createAdminRoutes from 'admin/pages';
import createNotFoundRoutes from 'notFound/pages';
import { createUKGSchedulingRoutes } from 'scheduling/pages';
import createRegionDataRoutes from 'regionData/pages';
import usePageTracking from 'common/hooks/usePageTracking';
import useMe from './common/hooks/useMe';
import useIsLoggedIn from './common/hooks/useIsLoggedIn';
import useTeamDataTabsEnabled from './common/hooks/useTeamDataTabsEnabled';

function RedirectToLandingPage() {
    return <Redirect to="/home" />;
}

const allRoutes = [
    { path: '/', exact: true, component: RedirectToLandingPage },
    ...createHomeRoutes('/home'),
    ...createAuthRoutes('/'),
    ...createAccountRoutes('/account'),
    ...createCompanyWideSettingsRoutes('/company-control-center'),
    ...createClinicControlCenterRoutes('/clinic-control-center'),
    ...createAdminRoutes('/admin'),
    ...createGoogleRoutes('/google'),
    ...createUKGSchedulingRoutes('/ukg-scheduling'),
    ...createOauthRoutes('/oauth'),
    ...createNotFoundRoutes(),
];

const clinicAndRegionDataRoutes = [
    ...createDashboardRoutes('/clinic-data'),
    ...createRegionDataRoutes('/region-data'),
];

interface CreateRouteProps {
    path: string;
    key?: string;
    isPublic?: boolean;
    roles?: ReadonlyArray<string>;
}

function createRoute({ path, key, isPublic, ...props }: CreateRouteProps) {
    const Component = isPublic ? Route : PrivateRoute;

    // we're in team-app – user needs to have staff role
    const roles = props.roles ? props.roles : isPublic ? [] : [ROLES.staff];

    return <Component key={key || path} path={path} {...props} roles={roles} />;
}

export default function TeamAppRoutes() {
    const isLoggedIn = useIsLoggedIn();
    const { enabled } = useTeamDataTabsEnabled();
    const me = useMe();
    usePageTracking();

    const routes = React.useMemo(
        () => [...(enabled ? clinicAndRegionDataRoutes : []), ...allRoutes],
        [enabled],
    );

    // Wait for me to load in order to select a location for redirect case
    if (isLoggedIn && !me) {
        return <Loading />;
    }

    return <Switch>{routes.map(createRoute)}</Switch>;
}
