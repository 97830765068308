import { ApolloQueryResult } from '@apollo/client/core/types';
import * as React from 'react';
import { useQuery } from '@apollo/client';
import { locationsWithVetspireIdQuery } from 'admin/api/queries';

export type LocationWithVetspireId = {
    _id: string;
    vetspireId: string;
    coupaWarehouseId: string | null;
    name: string;
    showPublically: boolean;
    ukgProLocationId: string | null;
    placeholderShiftsEnabled: boolean | null;
    placeholderShiftBlockOffTemplateId: string | null;
};

type LocationsWithVetspireIdResult = {
    locations: ReadonlyArray<LocationWithVetspireId>;
};

type LocationsWithVetspireId = {
    locations: ReadonlyArray<LocationWithVetspireId>;
    refetch: () => Promise<ApolloQueryResult<LocationsWithVetspireIdResult>>;
    isLoading: boolean;
};

export default function useLocationsWithVetspireId(
    includeClosed?: boolean,
    onCompleted?: (locations: ReadonlyArray<LocationWithVetspireId>) => void,
): LocationsWithVetspireId {
    const { data, loading, refetch } = useQuery<LocationsWithVetspireIdResult>(
        locationsWithVetspireIdQuery,
        {
            variables: {
                includeClosed: !!includeClosed,
            },
            fetchPolicy: 'cache-first',
            onCompleted: (data2) => {
                if (onCompleted) {
                    onCompleted(data2?.locations || []);
                }
            },
        },
    );

    return {
        locations: React.useMemo(
            () =>
                (data?.locations || [])
                    .slice(0)
                    .sort((a, b) => (a.name || '').localeCompare(b.name || '')),
            [data?.locations],
        ),
        refetch,
        isLoading: loading,
    };
}
