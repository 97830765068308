import {
    MeFields,
    MeFieldsType,
} from '_embedded_packages/web-app-auth/api/common';
import { gql } from '@apollo/client';
import {
    DbVetspireProvider,
    INDIVIDUAL_PROVIDER_RIGHT_KEYS,
    PROVIDER_RIGHT_KEY,
} from '@bondvet/types/providers';

export interface UserFragmentType extends MeFieldsType {
    vetspire: {
        id: string;
        url: string;
    };
    locations: ReadonlyArray<{
        _id: string;
        name: string;
    }>;
    regions: ReadonlyArray<{
        _id: string;
        name: string;
    }>;
    roles: ReadonlyArray<string>;
}

export const UserFragment = gql`
    fragment UserFragment on User {
        ...MeFields
        vetspire {
            id
            url
        }
        locations {
            _id
            name
        }
        regions {
            _id
            name
        }
        roles
        hasPassword
    }
    ${MeFields}
`;

export interface ClientFragmentType extends Omit<UserFragmentType, 'animals'> {
    animals: ReadonlyArray<{
        _id: string;
        name: string;
        vetspireId: string;
        species: string;
        dateOfBirth: string;
    }>;
    phoneNumber: string;
    intlPhoneNumber: string;
}

export const ClientFragment = gql`
    fragment ClientFragment on User {
        ...UserFragment
        animals {
            _id
            name
            vetspireId
            species
            dateOfBirth
        }
        phoneNumber
        intlPhoneNumber
    }
    ${UserFragment}
`;

export interface UKGEmployeeFragmentType {
    _id: string;
    first_name: string;
    last_name: string;
    primary_email: string;
    secondary_email: string;
    jobTitle?: string;
    status?: string;
}

export interface UKGProPersonFragmentType {
    _id: string;
    personNumber: string;
    employmentStatus: string;
    firstName: string;
    lastName: string;
    email: string;
}

export const UKGEmployeeFragment = gql`
    fragment UKGEmployeeFragment on UKGEmployee {
        _id
        status
        first_name
        last_name
        primary_email
        secondary_email
        jobTitle
    }
`;

export const UKGProPersonFragment = gql`
    fragment UKGProPersonFragment on UKGProPerson {
        _id
        personNumber
        employmentStatus
        firstName
        lastName
        email
    }
`;

export interface VetspireProviderFragmentType extends DbVetspireProvider {
    id: string;
    defaultLocation: {
        _id: string;
        name: string;
    };
    ukg: UKGEmployeeFragmentType;
    ukgPro: UKGProPersonFragmentType;
}

export const VetspireProviderFragment = gql`
    fragment VetspireProviderFragment on VetspireProvider {
        id
        firstName
        lastName
        isActive
        email
        username
        defaultLocation {
            _id
            name
        }
        ukg {
            ...UKGEmployeeFragment
        }
        ukgPro {
            ...UKGProPersonFragment
        }
        rights {
            vetspireExtension_tasks
            vetspireExtension_surgeryForms
            vetspireExtension_surgeryReferrals
            vetspireExtension_encounters
            vetspireExtension_creditCards
            vetspireExtension_chargeNoShowFees
            vetspireExtension_staffOptimizationUKG
            vetspireExtension_providerPayment
            vetspireExtension_gainsharing
            vetspireExtension_apptLog
            vetspireExtension_bonusly
            vetspireExtension_infoPdf
            vetspireExtension_externalReferrals
            vetspireExtension_medicalMishaps
            vetspireExtension_telehealth
            vetspireExtension_texting
            vetspireExtension_patientPlans
            vetspireExtension_travelCertificates
            vetspireExtension_suki
            vetspireExtension_intradayCapacity
            ${Object.keys(PROVIDER_RIGHT_KEY).join('\n')}
        }
        individualRights {
            ${INDIVIDUAL_PROVIDER_RIGHT_KEYS.join('\n')}
        }
        employmentStartDate
        employmentEndDate
        workingDays
        paymentPin
        canDoSurgeries
        canDoSpecialtySurgeries
        automateProviderPaymentMonths
        providerRole {
            id
            name
        }
    }
    ${UKGEmployeeFragment}
    ${UKGProPersonFragment}
`;
